import React from "react";
import Layout from "../components/Layout";
import "../components/styles/Booking.scss";

const Booking = ({ location }) => {
  const iframe = `<iframe id="booking-iframe" src="https://app.squarespacescheduling.com/schedule.php?owner=22942369" title="Schedule Appointment" width="100%" height="100%" frameBorder="0"></iframe><script src="https://embed.acuityscheduling.com/js/embed.js" type="text/javascript"></script>`;

  return (
    <Layout location={location}>
      <h1 style={{ display: "none" }}>
        Ms. Tang’s Nails and Spa Booking | Nail salon | Santa Maria, CA 93455
      </h1>
      <div dangerouslySetInnerHTML={{ __html: iframe }}></div>
    </Layout>
  );
};

export default Booking;
